require("jstree");

const treeContainerEl =
  '<div class="tree-container" data-tree-container></div>';
const searchContainerEl = '<div class="input-has-icon icon-search"></div>';
const searchInputEl = '<input type="text" data-tree-search>';
const clearSearchEl =
  '<a href="javascript:void(0)" class="icon icon-close is-hidden" data-tree-clear-search></a>';

const WoboTree = Backbone.View.extend({
  events: {
    "click [data-tree-clear-search]": "clearSearch",
    "click [data-make-default]": "makeAgencyDefault",
    "click [data-add-cascade]": "addAgencyCascade",
    "click [data-move-agent]": "openMoveAgentModal"
  },

  initialize: function() {
    this.settings = {
      id: "",
      isLoading: true,
      endpoints: {},
      tokens: {}
    };

    if (this.$el.data("settings")) {
      this.settings = $.extend(
        true,
        this.settings,
        eval(this.$el.data("settings"))
      );
    }

    this.$el.append(treeContainerEl);

    this.initTree();
    this.initTemplates();

    this.$el.loadComponents();
    this.$el.loadModules();
  },

  initTree: function() {
    const self = this;

    this.tree = this.$("[data-tree-container]");
    this.tree.wrap("<div class='form-field form-field-row is-loading'></div>");

    let options = {
      core: {
        expand_selected_onload: true,
        strings: {
          "Loading ...": ""
        }
      },
      plugins: ["types"],
      types: {
        agency: {
          icon: "icon-org-unit"
        }
      }
    };

    // endpoint that will provide the data
    options.core.data = { url: this.settings.data_url };

    // search plugin
    this.formField = $('<div class="form-field"></div>');
    this.searchEl = $(searchContainerEl);
    this.searchInputEl = $(searchInputEl);
    this.clearSearchEl = $(clearSearchEl);

    this.searchEl.append(this.searchInputEl);
    this.searchEl.append(this.clearSearchEl);

    this.formField.append(this.searchEl);

    if (this.$('[data-messages="' + this.settings.id + '"]')) {
      this.$('[data-messages="' + this.settings.id + '"]').after(
        this.formField
      );
    } else {
      this.$el.prepend(this.formField);
    }

    options.plugins.push("search");
    options.search = {
      show_only_matches: true,
      close_opened_onclear: true
    };

    // checkbox plugin
    options.plugins.push("checkbox");
    options.checkbox = {
      whole_node: true,
      three_state: false,
      keep_selected_style: false
    };

    // actions plugin
    this.pluginActions(this.settings);
    options.plugins.push("actions");
    options.actions = {};

    this.tree.jstree(options);

    // tree search event
    var to = false;
    this.$("[data-tree-search]").keyup(function() {
      if (self.searchInputEl.val().length) {
        self.clearSearchEl.removeClass("is-hidden");
        self.searchEl.removeClass("icon-search");
      } else {
        self.clearSearchEl.addClass("is-hidden");
        self.searchEl.addClass("icon-search");
      }

      if (to) {
        clearTimeout(to);
      }
      to = setTimeout(function() {
        var v = self.searchInputEl.val();
        self.tree.jstree(true).search(v);
      }, 250);
    });

    // tree select/deselect node
    this.tree.on("changed.jstree", function(e, action) {
      if (action.action && !self.isLoading) {
        const selectedNodeId = action.node?.id;
        if (selectedNodeId) {
          let data = new Backbone.Model(self.settings.csrf);
          data.set("working_on_behalf[org_unit_id]", selectedNodeId);

          if (action.action === "select_node") {
            data.set("working_on_behalf[add_org_unit]", 1);
            self._sendRequest(self.settings.endpoints["add"], data.toJSON());
          }
          if (action.action === "deselect_node") {
            self._sendRequest(self.settings.endpoints["add"], data.toJSON());
          }
        }
      }
    });

    // Tree is fully loaded and initialized
    this.tree.on("ready.jstree", () => {
      self.isLoading = false;
      self.unblock();
    });

    this.tree.on("refresh.jstree", () => {
      self.isLoading = false;
      self.unblock();
    });
  },

  initTemplates: function() {
    this.templates = {
      moveAgent: Handlebars.compile(
        this.$("[data-move-agent-template]").html() || ""
      ),
      messages: Handlebars.compile(
        this.$('[data-messages-template="' + this.settings.id + '"]').html() ||
          ""
      )
    };

    this.moveAgentModal = this.$("[data-move-agent-modal]");
  },

  clearSearch: function() {
    this.searchInputEl.focus().val("");
    this.searchInputEl.trigger("keyup");
  },

  refreshTree: function() {
    this.isLoading = true;

    this.block();
    this.tree.jstree(true).refresh(true, true);
  },

  makeAgencyDefault: function(e) {
    let data = new Backbone.Model(this.settings.csrf);
    data.set(
      "working_on_behalf[org_unit_id]",
      $(e.currentTarget).data("node-id")
    );

    this._sendRequest(
      this.settings.endpoints["default"],
      data.toJSON(),
      this.refreshTree.bind(this)
    );
  },

  addAgencyCascade: function(e) {
    let data = new Backbone.Model(this.settings.csrf);
    data.set(
      "working_on_behalf[org_unit_id]",
      $(e.currentTarget).data("node-id")
    );
    data.set(
      "working_on_behalf[is_cascade]",
      $(e.currentTarget).data("is-cascade") || 0
    );

    this._sendRequest(
      this.settings.endpoints["cascade"],
      data.toJSON(),
      this.refreshTree.bind(this)
    );
  },

  openMoveAgentModal: function(e) {
    const tree = this.tree.jstree(true);
    const selectedNode = tree.get_node($(e.currentTarget).data("node-id"));

    this.moveAgentModal.html(
      this.templates.moveAgent({
        data: {
          org_unit_id: $(e.currentTarget).data("node-id"),
          current: this.settings.currentAgencyName || "",
          name: selectedNode.text
        }
      })
    );

    const Dialog = this.moveAgentModal.component();
    Dialog.reflow().open();
  },

  _sendRequest: function(url, data, callback) {
    $.ajax({
      type: "POST",
      dataType: "json",
      cache: false,
      url: url,
      data: data,

      beforeSend: () => {
        this.renderMessages({});
        this.block();
      },

      success: response => {
        this.renderMessages(response.messages);

        // csrf tokens need to be updated for invalid response.
        if (response.csrf) {
          this.settings.csrf = response.csrf;
        }

        this.unblock();

        if (response.success && callback) {
          callback(response);
        }

        return true;
      },

      error: () => {
        this.renderMessages({ ajaxFailure: true });
        this.unblock();

        return false;
      }
    });
  },

  block: function() {
    this.$("[data-tree-container]")
      .parent()
      .addClass("is-loading");
  },

  unblock: function() {
    this.$("[data-tree-container]")
      .parent()
      .removeClass("is-loading");
  },

  renderMessages: function(messages) {
    this.$('[data-messages="' + this.settings.id + '"]').html(
      this.templates.messages(messages)
    );
  },

  /**
   * redraw_node - Override the redraw_node method to add custom buttons to tree nodes.
   *
   * @param {HTMLElement} obj - The DOM element representing the node being redrawn.
   * @param {boolean} deep - Flag indicating whether to redraw child nodes.
   * @param {Function} callback - Callback function to be executed after the node is redrawn.
   * @param {boolean} force_draw - Flag indicating whether to force the redraw.
   * @returns {HTMLElement} - The modified DOM element representing the node.
   */

  pluginActions: function(settings) {
    $.jstree.plugins.actions = function(options, parent) {
      // Call the parent's redraw_node method to per
      this.redraw_node = function(obj, deep, callback, force_draw) {
        obj = parent.redraw_node.call(this, obj, deep, callback, force_draw);

        if (obj) {
          const nodeId = $(obj).attr("id");
          const node = this.get_node(nodeId);

          if (!node.state.disabled) {
            // actions
            const makeDefault = node.original.is_default
              ? `<label class="link is-default icon-primary">${
                  settings.tokens.isDefault
                }</label>`
              : `<button type="button" class="button is-anchor make-default" data-make-default data-node-id="${
                  node.id
                }">${settings.tokens.makeDefault}</button>`;

            const addCascade = node.original.is_cascade
              ? `<button type="button" class="button is-anchor is-cascade icon-tree-down" data-add-cascade data-node-id="${
                  node.id
                }">${settings.tokens.isCascade}</button>`
              : `<button type="button" class="button is-anchor add-cascade" data-add-cascade data-node-id="${
                  node.id
                }" data-is-cascade="1">${settings.tokens.addCascade}</button>`;

            const moveAgent =
              settings.agentOrgUnitId === undefined ||
              settings.agentOrgUnitId !== Number(node.id)
                ? `<button type="button" class="button is-anchor last-action" data-move-agent data-node-id="${
                    node.id
                  }">${settings.tokens.moveAgent}</button>`
                : `<span>${settings.tokens.currentAgency}</span>`;

            const ulElement = $(obj)
              .find("ul")
              .first();

            // If the node is opened, insert buttons before the <ul> element,
            // otherwise append them to the node
            if (node.state.opened) {
              ulElement.before(
                `<div class="jstree-actions-wrapper">${makeDefault}${addCascade}${moveAgent}</div>`
              );
            } else {
              $(obj).append(
                `<div class="jstree-actions-wrapper">${makeDefault}${addCascade}${moveAgent}</div>`
              );
            }
          }
        }

        return obj;
      };
    };
  }
});

module.exports = WoboTree;
