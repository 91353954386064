var $ = require("jquery");
var ccTypes = require("./../../utilities/libraries/credit-card-types");

var CreditCard = Backbone.Model.extend({
  defaults: {
    selections: {}
  }
});

var PaymentMethodView = require("./_payment.payment-method");

var CreditCardView = PaymentMethodView.extend({
  events: function() {
    var parentEvents = _.result(PaymentMethodView.prototype, "events");

    return _.extend(
      {
        "change [data-profile-credit-cards]": "processCreditCardSelection",
        "change [data-credit-card-exp-month]": "updateYears",
        "change [data-credit-card-exp-year]": "updateMonths"
      },
      parentEvents
    );
  },

  initialize: function(options) {
    PaymentMethodView.prototype.initialize.call(this, options);

    // Set moment locale
    moment.locale(REVELEX.settings.currentLocale);
    this.model = new CreditCard(this.$el);

    this.initDates = options.initDates;

    this.monthInput = this.$("[data-credit-card-exp-month]");

    this.currentMonth = parseInt(moment().format("M"));
    this.currentYear = parseInt(moment().format("YYYY"));

    if (
      options.settings &&
      options.settings.billingInfo &&
      options.settings.billingInfo.credit_card
    ) {
      if (options.settings.billingInfo.credit_card.expiration_month) {
        this.currentMonth =
          options.settings.billingInfo.credit_card.expiration_month;
      }

      if (options.settings.billingInfo.credit_card.expiration_year) {
        this.currentYear =
          options.settings.billingInfo.credit_card.expiration_year;
      }
    }

    this.cvvAvailable = this.$("[data-payment-credit-card-cvv]").length;

    // creating the default list of months, you need to pass the actual month and year to validate properly
    this.createMonthsList(this.currentMonth, this.currentYear, true, true);

    // creating the default list of years.
    this.createYearsList(false, this.currentMonth, true);

    this.$el.loadTemplates({ data: this.model });

    this.model.on("change:selections", this.$el.renderItems.bind(this));

    if (options.settings && options.settings.runCreditCardOnInit) {
      let startCardType = this.$("[data-credit-card-type-select]").val() || 0;

      this.processSelection(this, startCardType);
    }
  },

  processPaymentMethodTypeChange: function(data) {
    PaymentMethodView.prototype.processPaymentMethodTypeChange.call(this, data);

    if (
      data.paymentMethodType.get("type_label") !==
      this.paymentMethodType.get("type_label")
    ) {
      this.$("[data-profile-credit-cards]").attr("disabled", true);
      this.$("[data-payment-credit-card-type]").attr("disabled", true);
      this.$("[data-payment-credit-card-number]").attr("disabled", true);
      this.$("[data-payment-credit-card-first-name]").attr("disabled", true);
      this.$("[data-payment-credit-card-last-name]").attr("disabled", true);
      this.$("[data-payment-credit-card-exp-month]").attr("disabled", true);
      this.$("[data-payment-credit-card-exp-year]").attr("disabled", true);
      this.$("[data-payment-credit-card-profile-save]").attr("disabled", true);
      this.$("[data-payment-credit-card-stored-card-framework]").attr(
        "disabled",
        true
      );

      this.toggleField("[data-payment-credit-card-issue-month]", false);
      this.toggleField("[data-payment-credit-card-issue-year]", false);
      if (this.cvvAvailable) {
        this.toggleField("[data-payment-credit-card-cvv]", false);
      }
    }
  },

  processSelection: function(event, value) {
    PaymentMethodView.prototype.processSelection.call(this, event);

    let method = this.paymentMethods.find({
      component_id: value ? value : $(event.currentTarget).val()
    });

    this.$("[data-profile-credit-cards]").attr("disabled", false);
    this.$("[data-payment-credit-card-type]").attr("disabled", false);
    this.$("[data-payment-credit-card-first-name]").attr("disabled", false);
    this.$("[data-payment-credit-card-last-name]").attr("disabled", false);
    this.$("[data-payment-credit-card-exp-month]").attr("disabled", false);
    this.$("[data-payment-credit-card-exp-year]").attr("disabled", false);
    this.$("[data-payment-credit-card-profile-save]").attr("disabled", false);
    this.$("[data-payment-credit-card-stored-card-framework]").attr(
      "disabled",
      false
    );

    this.toggleField("[data-payment-credit-card-issue-month]", false);
    this.toggleField("[data-payment-credit-card-issue-year]", false);
    if (this.cvvAvailable) {
      this.toggleField("[data-payment-credit-card-cvv]", false);
    }

    if (method) {
      var config = method.get("credit_card");

      this.toggleField(
        "[data-payment-credit-card-issue-month]",
        config.mandatory_valid_from_date
      );
      this.toggleField(
        "[data-payment-credit-card-issue-year]",
        config.mandatory_valid_from_date
      );

      if (this.cvvAvailable) {
        this.toggleField(
          "[data-payment-credit-card-cvv]",
          config.mandatory_cvv
        );
      }

      this.$("[data-payment-credit-card-number]").attr(
        "maxlength",
        ccTypes[config.code].maxlength || ccTypes["none"].maxlength
      );
      this.$("[data-payment-credit-card-number]").attr(
        "pattern",
        ccTypes[config.code].pattern || ccTypes["none"].pattern
      );

      if (this.cvvAvailable) {
        this.$("[data-payment-credit-card-cvv]").attr(
          "minlength",
          ccTypes[config.code].cvvlength || ccTypes["none"].cvvlength
        );
        this.$("[data-payment-credit-card-cvv]").attr(
          "maxlength",
          ccTypes[config.code].cvvlength || ccTypes["none"].cvvlength
        );
      }

      this.$el.loadComponents();
      this.$el.loadModules();

      this.$("[data-payment-credit-card-number]").attr("disabled", false);
    } else {
      this.$("[data-payment-credit-card-number]").attr(
        "maxlength",
        ccTypes["none"].maxlength
      );
      this.$("[data-payment-credit-card-number]").attr(
        "pattern",
        ccTypes["none"].pattern
      );

      if (this.cvvAvailable) {
        this.$("[data-payment-credit-card-cvv]").attr(
          "minlength",
          ccTypes["none"].cvvlength
        );
        this.$("[data-payment-credit-card-cvv]").attr(
          "maxlength",
          ccTypes["none"].cvvlength
        );
      }
      this.$("[data-payment-credit-card-number]").attr("disabled", true);
    }

    this.$el.removeClass("is-hidden");
  },

  /**
   * This method processes the selection of a credit card.
   *
   * @param event The selection event
   */
  processCreditCardSelection: function(event) {
    let currentDropdown = $(event.currentTarget);
    var selection = currentDropdown.find("option:selected");

    this.render(new CreditCard(selection.data("profile-card")));
  },

  /**
   * This method updates the view display based on the supplied credit card
   *
   * @param creditCard The credit card data to use to render the view
   */
  render: function(creditCard) {
    var option = this.$("[data-credit-card-type-select]").find(
      '[data-credit-card-type="' + creditCard.get("type_id") + '"]:first'
    );

    this.$("[data-credit-card-type-select]")
      .val(option.val())
      .trigger("change");
    this.$("[data-credit-card-number]").val(creditCard.get("number"));
    this.$("[data-credit-card-first-name]").val(creditCard.get("first_name"));
    this.$("[data-credit-card-last-name]").val(creditCard.get("last_name"));
    this.$("[data-credit-card-iss-month]").val(creditCard.get("issue_month"));
    this.$("[data-credit-card-iss-year]").val(creditCard.get("issue_year"));

    let creditCardExpMonth = this.$("[data-credit-card-exp-month]");
    let creditCardExpYear = this.$("[data-credit-card-exp-year]");

    creditCardExpYear.find(
      "option[value='" + creditCard.get("expiration_year") + "']"
    ).length
      ? creditCardExpYear
          .find("option[value='" + creditCard.get("expiration_year") + "']")
          .prop("selected", true)
      : creditCardExpYear.find("option:eq(0)").prop("selected", true);

    this.updateMonths(creditCardExpYear);

    creditCardExpMonth.find(
      "option[value='" + creditCard.get("expiration_month") + "']"
    ).length
      ? creditCardExpMonth
          .find("option[value='" + creditCard.get("expiration_month") + "']")
          .prop("selected", true)
      : creditCardExpMonth.find("option:eq(0)").prop("selected", true);

    if (creditCard.get("number")) {
      this.$("[data-credit-card-number]").attr("readonly", "readonly");
    } else {
      this.$("[data-credit-card-number]").removeAttr("readonly");
    }

    creditCardExpMonth.parsley().validate();
    creditCardExpYear.parsley().validate();

    var address = creditCard.get("address") || {};

    if (address.address_id) {
      this.dispatcher.trigger("creditCard:changed", {
        addressId: address.address_id
      });
    }

    if (creditCard.get("stored_card_framework_accepted")) {
      this.$("[data-payment-credit-card-stored-card-framework]").attr(
        "disabled",
        true
      );
      this.$(
        "[data-payment-credit-card-stored-card-framework-container]"
      ).addClass("is-hidden");
    } else {
      this.$("[data-payment-credit-card-stored-card-framework]").attr(
        "disabled",
        false
      );
      this.$(
        "[data-payment-credit-card-stored-card-framework-container]"
      ).removeClass("is-hidden");
    }
  },

  /**
   * This method updates the list of years as per the month selected
   */
  updateYears: function(event) {
    var selectedMonth = parseInt($(event.currentTarget).val());

    //if selected month comes before the current month then remove the current year from the year list, else display
    // the default year list
    this.currentMonth > selectedMonth
      ? this.createYearsList(this.currentYear + 1, selectedMonth)
      : this.createYearsList(this.currentYear, selectedMonth);
  },

  /**
   * This method updates the list of months as per the year selected
   */
  updateMonths: function(event) {
    let currentEvent = event.currentTarget ? event.currentTarget : event;
    let selectedYear = parseInt($(currentEvent).val() || this.currentYear);

    //if selected year is the current year then remove the past months.
    this.currentYear === selectedYear
      ? this.createMonthsList(this.currentMonth, selectedYear, true)
      : this.createMonthsList(0, selectedYear);
  },

  createMonthsList: function(index, selectedYear, sameYear, isInit) {
    // We need to include the current month on the expiration array
    let selection = this.model.get("selections") || {},
      start_index = 0,
      monthsName = {},
      currentMonth = this.monthInput.val();

    //creating an object of month number and month name
    for (let i = start_index; i < 12; i++) {
      monthsName[i + 1] = moment.months(i);
    }

    selection["months"] = monthsName;

    let validMonth =
      parseInt(this.monthInput.val() || 0) < parseInt(this.currentMonth)
        ? this.currentMonth
        : this.monthInput.val();

    if (!isInit || this.initDates) {
      selection["selectedMonth"] = sameYear ? validMonth : currentMonth;
      selection["selectedYear"] = selectedYear ? selectedYear : "";
    }

    this.model.set("selections", selection);
    this.model.trigger("change:selections");
  },

  createYearsList: function(startYear, selectedMonth, isInit) {
    let selection = this.model.get("selections") || {},
      years = [],
      endYear = parseInt(
        moment()
          .add(10, "years")
          .format("YYYY")
      );
    startYear = startYear ? startYear : this.currentYear;
    // creating an Array of years from current year to 10 years ahead .
    for (var i = startYear; i <= endYear; i++) {
      years.push(i);
    }

    if (!isInit || this.initDates) {
      selection["selectedMonth"] = selectedMonth ? selectedMonth : "";
    }

    selection["years"] = years;
    this.model.set("selections", selection);
    this.model.trigger("change:selections");
  }
});

module.exports = CreditCardView;
