/**
 * Omega Tooltip component
 */

var TooltipView = Backbone.View.extend({
  events: {
    "click [data-tooltip-close]": "closeTooltip"
  },

  initialize: function() {
    //Default header
    this.header =
      '<div class="tooltip-header">' +
      '<h4 class="tooltip-title" data-tooltip-title></h4>' +
      "</div>";

    this.containerArrow =
      '<div class="tooltip-arrow" data-container-arrow></div>';

    //Tooltip component id
    this.id = this.$el.data("tooltip-id");
    this.eventNamespace = this.id
      ? this.id.replace(/[\[\]\.\(\)]/g, "-")
      : false;

    this.currentContainerArrow = this.$el.find("[data-container-arrow]").length;

    if (!this.currentContainerArrow) {
      this.$el.prepend(this.containerArrow);
      this.currentContainerArrow = this.$el.find("[data-container-arrow]");
    }

    //Component loaded
    this.loaded = false;

    //Saving tooltip default content
    this.initialContent = this.$el.find("[data-tooltip-content]").html();

    //If we have multiple triggers for the same tooltip component
    //Add data-multiple-trigger
    this.multipleTrigger = this.$el.data("multipleTrigger");

    //Click on specific tooltip trigger (data-tooltip-trigger == data-tooltip-id)
    //Linked to $(document) because the trigger it's out of this view scope
    $(document).on(
      "click.tooltip" + this.eventNamespace,
      '[data-tooltip-trigger="' + this.id + '"]',
      this.showTooltip.bind(this)
    );

    let isDialog = $("[data-dialog-overlay]");
    let currentWrapper = isDialog.is(".is-active") ? isDialog : $(document);

    //Close tooltip on scroll
    currentWrapper.on(
      "scroll.tooltip" + this.eventNamespace,
      this.closeTooltip.bind(this)
    );

    if (
      this.$el.data("tooltipOpenOnInit") &&
      this.$el.data("tooltipShowOnce")
    ) {
      this.showTooltipOnce();
    } else if (this.$el.data("tooltipOpenOnInit")) {
      this.showTooltip();
    }

    //Optional: requires data-tooltip-show-hover="true"
    if (!this.$el.data("tooltipHoverDisabled")) {
      //Hover on specific tooltip trigger (data-tooltip-trigger == data-tooltip-id)
      $(document).on(
        "mouseover.tooltip" + this.eventNamespace,
        '[data-tooltip-trigger="' + this.id + '"]',
        this.showTooltipHover.bind(this)
      );
      $(document).on(
        "mouseleave.tooltip" + this.eventNamespace,
        '[data-tooltip-trigger="' + this.id + '"]',
        this.hideTooltipLeave.bind(this)
      );
    }

    //Optional: requires data-tooltip-outside-click-close="true"
    if (this.$el.data("tooltipOutsideClickClose")) {
      //Close tooltip on click outside
      $(document).on(
        "click.tooltip" + this.eventNamespace,
        this.clickOutside.bind(this)
      );
    }

    this.$el.attr("aria-hidden", true).addClass("is-hidden");

    this.openTimeout = false;
    this.closeTimeout = false;

    // resize event to control tooltip location
    $(window).on("resizeView", () => {
      setTimeout(this.tooltipPosition.bind(this), 600);
    });
  },

  unload: function() {
    $(document).off(".tooltip" + this.eventNamespace);
  },

  /**
   * Re-building tooltip content
   * @param data from the tooltip trigger element
   */
  reflow: function(data) {
    /**
     * Expected structure:
     *  data-trigger-data = '{
     *    "header": "<Your tooltip header here/>",
     *    "body": "<Your tooltip body here/>",
     *    "footer": "<Your tooltip footer here/>"
     *  }'
     * OR
     *  data-trigger-data = "Your information here"
     **/

    if (data.triggerData && typeof data.triggerData === "object") {
      this.body = data.triggerData.body ? data.triggerData.body : false;
      this.header = data.triggerData.header
        ? data.triggerData.header
        : this.header;
      this.footer = data.triggerData.footer ? data.triggerData.footer : false;
    } else if (data.triggerData && typeof data.triggerData === "string") {
      this.body = data.triggerData;
    }

    //Load content if it's not loaded OR it's a multiple trigger tooltip
    //A multiple tooltip should have only informational content (Could be HTML but it won't be sent to Back End)
    if (!this.loaded || this.multipleTrigger) {
      this.loaded = true;

      if (data.triggerData) {
        //Cleaning the tooltip content if trigger contains data
        this.$el.find("[data-tooltip-content]").empty();

        //Appending initial content
        let tooltipContent = this.$el
          .find("[data-tooltip-content]")
          .html(this.initialContent);

        //Appending new content
        this.body ? tooltipContent.append(this.body) : false;
        this.footer ? tooltipContent.append(this.footer) : false;
      } else {
        let tooltipContent = this.$el.find("[data-tooltip-content]");
      }

      //If we have a title, append the header markup and the content
      if (data.tooltipTitle || (data.triggerData && data.triggerData.header)) {
        this.$el.prepend(this.header);
        this.$el.find("[data-tooltip-title]").length
          ? this.$el.find("[data-tooltip-title]").text(data.tooltipTitle)
          : "";
      }
    }

    //Loading new components
    this.$el.loadComponents();

    return this;
  },

  /**
   * Calculates tooltip trigger position and moves tooltip container
   * Default tooltip position: bottom
   * NOTE: Especially useful for cases where we can find multiple triggers for the same tooltip component
   */
  tooltipPosition: function() {
    if (!this.trigger.length || typeof this.trigger === "function") {
      return false;
    }
    let height = 0;
    let currentTooltipPosition = 0;
    let data = this.trigger.data();
    let triggerPosition = this.trigger[0].getBoundingClientRect();
    let margin = 0;

    this.$el.prepend(this.currentContainerArrow);

    //If data-tooltip-top=true, then display tooltip on top of the trigger

    this.$el.removeClass("tooltip-arrow-down tooltip-arrow-up");

    this.$el.removeClass("tooltip-container-top");

    if (data.tooltipTop) {
      //tooltip height, only when it displays on top
      height = this.$el.outerHeight();
      this.$el.addClass("tooltip-arrow-down");
      currentTooltipPosition = triggerPosition.top - height;
    } else {
      this.$el.addClass("tooltip-container-top tooltip-arrow-up");
      currentTooltipPosition = triggerPosition.top + triggerPosition.height;
    }

    // Position definitions
    const positionDef = {
      left: { left: triggerPosition.left },
      center: {
        left: triggerPosition.left + triggerPosition.width / 2,
        transform: "translateX(-50%)"
      },
      right: { right: triggerPosition.right }
    };

    //Moving tooltip depending on position value on data attributes
    const position = positionDef[data.tooltipPosition]
      ? positionDef[data.tooltipPosition]
      : {};

    //Adding top position (+ margin + height to prevent overlapping between trigger and tooltip)
    position.top = currentTooltipPosition;
    position.left = triggerPosition.left;

    // horizontal position depending on how far the end of viewport is
    let space = innerWidth - triggerPosition.left + this.el.offsetWidth;

    if (triggerPosition.left + this.el.offsetWidth > space) {
      position.left =
        triggerPosition.left + triggerPosition.width - this.el.offsetWidth;
    }

    //Removes previous applied styles
    this.$el.removeAttr("style");
    this.$el.css(position);
  },

  //Shows or hides tooltip on click
  showTooltip: function(event) {
    //Position of the element that triggers the tooltip
    this.trigger =
      typeof event !== "undefined"
        ? $(event.currentTarget)
        : $("[data-tooltip-ref=" + this.$el.data("tooltipId") + "]");

    //If tooltip is not open, set clicked as true and open the tooltip
    if (!this.open) {
      //Retrieving data from trigger attributes
      this.reflow(this.trigger.data());

      this.openTooltip();
    } else {
      this.closeTooltip();
    }
  },

  showTooltipOnce: function() {
    let tooltipId = this.$el.data("tooltipId");
    if (sessionStorage.getItem(tooltipId) !== "false") {
      sessionStorage.setItem(tooltipId, "false");
      this.showTooltip();
    }
  },

  /**
   * Open the tooltip
   */
  openTooltip: function(event) {
    //Set open = true and shows tooltip
    this.open = true;
    this.$el.attr("aria-hidden", false);

    this.$el.removeClass("is-hidden");

    this.tooltipPosition();

    this.openTimeout = setTimeout(() => {
      clearTimeout(this.closeTimeout);

      this.open = true;
      this.$el.attr("aria-hidden", false);
      this.$el.removeClass("is-hidden");

      this.$el.addClass("is-open").removeClass("is-hover");
      this.trigger.addClass("is-active").removeClass("is-hover");
    }, 400);
  },

  /**
   * Close the tooltip
   */
  closeTooltip: function(event) {
    if (this.open) {
      this.open = false;
      this.$el.attr("aria-hidden", true);
      this.$el.removeClass("is-open is-hover");
      this.trigger.removeClass("is-active is-hover");

      this.closeTimeout = setTimeout(() => {
        clearTimeout(this.openTimeout);

        this.open = false;
        this.$el.attr("aria-hidden", true);
        this.$el.removeClass("is-open is-hover");
        this.trigger.removeClass("is-active is-hover");

        this.$el.addClass("is-hidden");
      }, 400);
    }
  },

  /**
   * Show tooltip on mouseover
   */
  showTooltipHover: function(event) {
    this.trigger = $(event.currentTarget);
    if (!this.open) {
      //Retrieving data from trigger attributes
      this.reflow(this.trigger.data());

      this.tooltipPosition();

      this.$el.removeClass("is-hidden");

      setTimeout(() => {
        this.$el.addClass("is-open is-hover");
      }, 400);
    }
  },

  /**
   * Hide tooltip on mouseleave
   */
  hideTooltipLeave: function() {
    if (!this.open) {
      this.$el.removeClass("is-open is-hover");
      this.trigger.removeClass("is-active is-hover");

      setTimeout(() => {
        this.$el.addClass("is-hidden");
      }, 400);
    }
  },

  /**
   * Close the tooltip on click outside its container
   * Optional: data-tooltip-outside-click-close="true"
   */
  clickOutside: function(event) {
    var target = $(event.target);
    if (
      this.open &&
      target.data("tooltipTrigger") !== this.id &&
      !target.closest(this.$el).length
    ) {
      this.closeTooltip();
    }
  }
});

module.exports = TooltipView;
