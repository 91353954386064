/**
 **  Collapser Component
 **/

var Collapser = Backbone.View.extend({
  initialize: function() {
    // Set unique ID for collapser
    this.id = this.$el.data("collapser-id");

    // Optional setting to close on outside click
    this.autoclose = this.$el.data("collapser-autoclose");

    // Optional setting to close on outside click
    this.enableEscape =
      typeof this.$el.data("collapser-enable-escape") !== "undefined"
        ? this.$el.data("collapser-enable-escape")
        : true;

    // Optional setting to add unique class to the body
    this.bodyClass = this.$el.data("collapser-body-class")
      ? "collapser-" + this.id + "-expanded"
      : false;

    // Optional setting to close nested collapsers
    this.nestedCollapsersClose =
      this.$el.data("collapser-nested-collapsers-close") || false;

    //Optional setting, Group of triggers
    this.groupedTrigger = this.$el.data("collapser-grouped-trigger") || false;

    if (this.groupedTrigger) {
      this.listGroupedTriggers = $(
        '[data-collapser-trigger="' + this.id + '"]'
      );
    }

    // Optional setting to toggle Disable Attrs on nested form elements
    this.toggleDisabled = this.$el.data("collapser-toggle-disabled") || false;

    // Optional setting to disable form fields when collapser is closed
    this.disableFieldsOnClose =
      this.$el.data("collapser-disable-fields-on-close") || false;

    this.scrollTop = this.$el.data("collapser-scroll-top") || false;
    this.scrollOffset = this.$el.data("collapser-scroll-offset") || 20;

    // Set default state
    this.state = false;

    // Optional setting to register to Global Router.
    this.router = this.$el.data("collapser-routed")
      ? Router.registerComponent(this.id, this.onRouted, this)
      : false;

    // Cache Content
    this.content = this.$el.html();

    // Render main template under collapser
    this.renderView = !!this.$el.data("collapserRender");
    this.renderedView = false;

    // Optional Group ID
    this.groupId = this.$el.data("collapser-group") || false;
    this.group = this.groupId
      ? this.registerGroup(this.$el.data("collapser-group"))
      : false;

    // Optional setting to share state for group collapsers
    this.hoverSwitch =
      this.group && this.$el.data("collapser-hover-switch") ? true : false;

    // If a hash is present for the current id, ignore init State.
    if (
      (this.router && !this.router.ifComponentPresent(this.id)) ||
      !this.router
    ) {
      // If component has .is-expanded, it will run the open functionality
      if (this.$el.hasClass("is-expanded")) {
        this.open(true);
      } else {
        this.close();
      }
    }

    // Namespaced bind for the document to allow controls from outside of the component
    $(document).on(
      "click.collapser." + this.cid,
      "[data-collapser-expand-all]",
      this.expandAll.bind(this)
    );

    $(document).on(
      "click",
      "[data-collapser-close], [data-collapser-open]",
      function() {
        const isClosing = $(this).is("[data-collapser-close]");
        $("[data-collapser-traget]").toggleClass("is-hidden", isClosing);
      }
    );

    $(document).on(
      "click.collapser." + this.cid + " keyup.collapser." + this.cid,
      "[data-collapser-trigger]",
      e => {
        let pressedKey = typeof e.which == "number" ? e.which : e.keyCode;

        if (
          e.type === "keyup" &&
          (pressedKey !== 13 && pressedKey !== 32 && pressedKey !== 27) &&
          !this.enableEscape
        ) {
          return false;
        }
        this.currentTrigger = e.currentTarget;
        this.collapse(e, pressedKey);
      }
    );

    $(document).on("keyup.collapser." + this.cid, "[data-collapser-id]", e => {
      let pressedKey = typeof e.which == "number" ? e.which : e.keyCode;

      if (
        this.id === $(e.currentTarget).attr("data-collapser-id") &&
        !$(e.target).parents("[data-main-elements-output]").length &&
        e.type === "keyup" &&
        pressedKey === 27 &&
        this.enableEscape
      ) {
        this.close(e);
        e.stopPropagation();
      }
    });

    // Optional setting to add unique class to the body when expand-all is triggered
    this.expandAllBodyClass = this.$el.data("collapser-expand-all-class")
      ? "collapser-expanded-" + this.group
      : false;

    // Load components flag.
    this.loaded = false;

    // Trigger the on init method if a child field has the method
    if (_.isFunction(this.onInit)) {
      this.onInit();
    }

    // Load components even if the collapser is not expanded
    // Save a reference of the child components to have them accessible to unload them
    if (this.$el.data("collapser-autoload-components")) {
      this.childComponents = this.$el.loadComponents();
    }
  },

  reset: function() {
    this.close();
  },

  unload: function(c) {
    $(document).off("click.collapser." + this.cid);

    $(this.childComponents).trigger("unload");
  },

  onRouted: function(routes, state) {
    if (state === "open" && !this.isOpen()) {
      this.open();
    }

    if (state === "closed" && this.state) {
      this.close();
    }
  },

  registerGroup: function(group) {
    // Listen to its groups events
    Dispatcher.on(
      "groupedCollapsers:" + group,
      function(id) {
        // If event was triggered in different id and current collapser is open, close.
        if (this.state && this.id !== id) {
          this.close();
        }
      }.bind(this)
    );
    return group;
  },

  expandAll: function(e) {
    var trigger = $(e.currentTarget);

    if (trigger.data("collapser-expand-all") === this.group) {
      this.expandAllTrigger = !trigger.hasClass("collapser-expanded");

      this.expandAllToggle(trigger);
    }
  },

  expandAllToggle: function(trigger) {
    var hasClass = trigger.hasClass("collapser-expanded");
    if (!hasClass) {
      this.open(null, true);
    } else {
      this.close();
    }
  },

  collapse: function(e, pressedKey) {
    var triggers = $(e.currentTarget).data("collapser-trigger"),
      //check if expandAll of the group is already expanded or not
      isExpandAllActive = $(
        '[data-collapser-expand-all="' + this.group + '"]'
      ).hasClass("collapser-expanded"),
      //does the trigger belongs to the expand all group
      hasExpandAll = $(e.currentTarget).data("collapser-with-expand-all"),
      currentCollapse = false;

    this.event = e;
    this.triggerClicked = $(e.currentTarget);

    // Check for comma separated triggers
    _.each(
      triggers.split(","),
      function(id) {
        // True if current collapser matches with any of the ids
        if (this.id === id) {
          currentCollapse = true;
        }
      }.bind(this)
    );
    0;
    if (e && e.currentTarget && currentCollapse) {
      //if ExpandAll of a group is active close all the collapsers in the group except the triggered one
      if (isExpandAllActive && hasExpandAll) {
        Dispatcher.trigger("groupedCollapsers:" + this.group, this.id);
      } else if (!$(e.currentTarget).data("collapser-trigger-action")) {
        // If no action specified, toggle

        // if the collapser is closed and the escape is closed,
        // ignore opening it
        if (e.type === "keyup" && pressedKey === 27 && !this.state) {
          return false;
        } else {
          if (this.enableEscape) {
            this.toggle();
          }
        }
      } else {
        // Store Action
        var action = $(e.currentTarget).data("collapser-trigger-action");

        // Explicitly execute declared action
        if (action === "open") {
          this.open();
        } else if (action === "close") {
          this.close();
        }
      }
    }
    return this;
  },

  toggle: function() {
    if (!this.state) {
      this.open();
    } else {
      this.close();
    }
    return this;
  },

  open: function(isInit, triggerAll) {
    // If component is open, return
    if (this.state) {
      return this;
    }

    // Update state
    this.state = true;

    // When the trigger has a view to render
    // This will render it on the targeted element

    if (this.renderView) {
      // Checking if there's any content loaded
      if (!this.renderedView) {
        // making the app aware of the collapser loading the template
        this.$el.trigger("collapserView:loading");

        // checking for any available views to be rendered,
        // if found it gets removed
        let availableViews =
          $("[data-collapser-rendered-view='" + this.group + "']") || false;

        if (availableViews.length) {
          availableViews.remove();
        }

        const currentViewTab = this.el.querySelector("[data-collapser-view]");

        if (currentViewTab) {
          let currentContent =
            "<div data-collapser-rendered-view='" +
            this.group +
            "'>" +
            currentViewTab.innerHTML +
            "</div>";

          // We place hotel details before the template
          currentViewTab.insertAdjacentHTML("beforebegin", currentContent);

          this.$el.loadModules();
          this.$el.loadComponents();
          this.$el.trigger("collapserView:loaded");
        }
        this.renderedView = true;
      }
    }

    // Update state class made async for it to not bother with animation
    this.$el.removeAttr("style").removeClass("is-hidden");

    setTimeout(() => {
      // Remove 'Display None'

      this.$el.removeAttr("style").removeClass("is-hidden");

      this.$el.addClass("is-expanded");

      if (this.scrollTop && !isInit) {
        this.handleScroll();
      }

      // Aria attr update
      this.$el.attr("aria-hidden", "false");

      //If collapser does not have grouped triggers
      // or open function was not called by click event on this collapser trigger.
      let currentTrigger =
        !this.groupedTrigger || !this.triggerClicked
          ? // Update all trigger btns with target state class
            $(
              '[data-collapser-trigger="' +
                this.id +
                '"], [data-collapser-body="' +
                this.id +
                '"]'
            )
          : //else, update only clicked trigger
            this.triggerClicked;

      currentTrigger.addClass("collapser-expanded").attr("aria-expanded", true);

      // if there's a groupId it will add look for the group
      // body to add a class to it
      this.groupId
        ? $('[data-collapser-group-body="' + this.groupId + '"]')
            .addClass("group-" + this.groupId + "-expanded")
            .attr("aria-expanded", true)
        : false;

      // Update state
      this.state = true;

      if (
        this.triggerClicked &&
        this.triggerClicked.attr("data-collapser-focus") &&
        (this.event.keyCode === 32 || this.event.keyCode === 13)
      ) {
        this.event.preventDefault();
        this.$el.attr("tabindex", 0);
        this.$el.trigger("focus");
      }

      if (!this.loaded) {
        // Update loaded state
        this.loaded = true;

        // If first time it opens, load components.
        // Save a reference of the child components to have them accessible to unload them
        this.childComponents = this.$el.loadComponents();
        this.$el.loadModules();
      }

      if (this.expandAllTrigger) {
        $('[data-collapser-expand-all="' + this.group + '"]')
          .addClass("collapser-expanded")
          .attr("aria-expanded", true);
        if (this.expandAllBodyClass) {
          $("body").addClass(this.expandAllBodyClass);
        }
        this.expandAllTrigger = false;
      }

      // Global trigger to alert that the collapser has opened
      Backbone.Events.trigger(`collapser-${this.id}-open`).trigger(
        "collapser-opened"
      );
    });

    // Namespaced bind for click outside
    if (this.autoclose) {
      $(document).on(
        "click.collapserClose." + this.cid,
        this.outsideClick.bind(this)
      );
    }

    // If belongs to a group, trigger event
    if (this.group && !triggerAll) {
      Dispatcher.trigger("groupedCollapsers:" + this.group, this.id);
    }

    // If belongs to a group, trigger event
    if (this.group && this.hoverSwitch) {
      $(document).on(
        "mouseover.collapser." + this.cid,
        "[data-collapser-trigger]",
        this.processHoverSwitch.bind(this)
      );
    }

    if (this.bodyClass) {
      $("body").addClass(this.bodyClass);

      if (this.toggleDisabled) {
        this.$("input,select,textarea").removeAttr("disabled");
      }
    }

    if (this.router) {
      this.router.componentSwitch(this.id, "open");
    }

    this.trigger("open");

    this.disableFormFieldsOnClose(true);

    // checks for all children that have disable form fields inside and
    // if they are suppose to be disabled or not
    this.$(
      '[data-component="collapser"], [data-component="collapser-field"]'
    ).each((index, el) => {
      let currentChildCollapser = $(el).component();

      if (
        !currentChildCollapser.isOpen() &&
        currentChildCollapser.isDisableFields()
      ) {
        currentChildCollapser.disableFormFieldsOnClose(false);
      }
    });
  },

  disableFormFieldsOnClose: function(enableFields) {
    if (this.disableFieldsOnClose && enableFields === true) {
      this.$("input,select,textarea")
        .not('[type="button"]')
        .not("[data-collapser-ignore-disabled-field]")
        .attr("disabled", false)
        .trigger("change");
    } else if (this.disableFieldsOnClose) {
      this.$("input,select,textarea")
        .not('[type="button"]')
        .not("[data-collapser-ignore-disabled-field]")
        .attr("disabled", true);
    }
  },

  processHoverSwitch: function(e) {
    let currentCollapserTrigger = $(e.currentTarget).data("collapser-trigger");
    // If current target is not this view's label,
    // but belongs to the same group as this collapser, trigger click.
    if (
      currentCollapserTrigger !== this.id &&
      $('[data-collapser-id="' + currentCollapserTrigger + '"').data(
        "collapser-group"
      ) === this.group
    ) {
      $(e.currentTarget)
        .focus()
        .trigger("click");

      // Safely unbind namespaced event
      $(document).off("hover.collapser." + this.cid);
    }
  },

  outsideClick: function(e) {
    // If click wasn't inside view and state is open and its not a trigger button, close
    let currentTarget = $(e.target);

    if (
      !this.$el.is(currentTarget) &&
      !this.$(currentTarget).length &&
      this.state &&
      !($(e.target).data("collapser-trigger") === this.id) &&
      !currentTarget.closest("[data-parent-autoclose]").length
    ) {
      if (!this.$el.is("[data-collapser-disable-autoclose]")) {
        this.close();
      }
    }
  },

  close: function(e) {
    // If component is closed, return
    if (!this.state && this.$el.attr("aria-hidden") === "false") {
      return false;
    }

    //If collapser has a group of triggers and close function was invoked by a click event
    if (this.triggerClicked && this.groupedTrigger) {
      //Status for collapser triggers that belong to the same group
      //if our filtered array.length > 0 means that other trigger still need the collapser open
      let groupedTriggersStatus = this.listGroupedTriggers
        .filter(".collapser-expanded")
        .toArray()
        .filter(function(trigger) {
          return !$(trigger).is(this.triggerClicked);
        }, this);
      // Collapser will stay open if there are any other trigger that has class collapser-expanded.
      // Depending if the trigger has or not the class, we add it or remove it,
      // and finally return false to stop executing this functions
      if (groupedTriggersStatus.length > 0) {
        this.triggerClicked.hasClass("collapser-expanded")
          ? this.triggerClicked
              .removeClass("collapser-expanded")
              .attr("aria-expanded", false)
          : this.triggerClicked
              .addClass("collapser-expanded")
              .attr("aria-expanded", true);
        return false;
      }
    }

    if (this.renderView && this.renderedView) {
      this.$("[data-collapser-rendered-view='" + this.group + "']").remove();
      this.renderedView = false;
    }

    // Update state class
    this.$el.removeClass("is-expanded");

    // Aria attr update
    this.$el.attr("aria-hidden", "true");

    if (this.currentTrigger) {
      $(this.currentTrigger).trigger("focus");
    }

    // Update all trigger btns with target state class
    $(
      '[data-collapser-trigger="' +
        this.id +
        '"], [data-collapser-body="' +
        this.id +
        '"]'
    )
      .removeClass("collapser-expanded")
      .attr("aria-expanded", false);

    // if there's a groupId it will add look for the group
    // body to remove expanded class
    this.groupId
      ? $('[data-collapser-group-body="' + this.groupId + '"]')
          .removeClass("group-" + this.groupId + "-expanded")
          .attr("aria-expanded", false)
      : false;

    // Update state
    this.state = false;

    // Remove binding
    if (this.autoclose) {
      $(document).off("click.collapserClose." + this.cid);
    }

    // If belongs to a group, trigger event
    if (this.group && this.hoverSwitch) {
      $(document).off("mouseover.collapser." + this.cid);
    }

    // Empty Container after our standard 0.4s delay
    setTimeout(
      function() {
        if (!this.state && !this.$el.hasClass("is-expanded")) {
          this.$el.css({ display: "none" }).addClass("is-hidden");

          if (!this.expandAllTrigger) {
            $('[data-collapser-expand-all="' + this.group + '"]')
              .removeClass("collapser-expanded")
              .attr("aria-expanded", false);
            if (this.expandAllBodyClass) {
              $("body").removeClass(this.expandAllBodyClass);
            }
          }
        }
        this.$el.trigger("collapserView:closed");
      }.bind(this),
      400
    );

    if (this.nestedCollapsersClose) {
      this.closeNestedCollapsers();
    }

    if (this.bodyClass) {
      $("body").removeClass(this.bodyClass);
      if (this.toggleDisabled) {
        this.$("input,select,textarea")
          .not('[type="button"]')
          .attr("disabled", true);
      }
    }

    this.trigger("close");

    if (this.router) {
      this.router.componentSwitch(this.id, "closed");
    }

    this.disableFormFieldsOnClose(false);

    return this;
  },

  closeNestedCollapsers: function() {
    this.$(
      '[data-component="collapser"], [data-component="collapser-field"]'
    ).each(function() {
      $(this)
        .component()
        .close();
    });
    return this;
  },

  isOpen: function() {
    return this.state;
  },

  isDisableFields: function() {
    return this.disableFieldsOnClose;
  },

  handleScroll: function() {
    if (_.isNumber(this.scrollTop)) {
      offset = this.scrollTop;
    } else {
      offset = $(this.scrollTop).offset().top - this.scrollOffset;
    }

    $("html,body").animate(
      {
        scrollTop: offset
      },
      600
    );
  }
});

module.exports = Collapser;
