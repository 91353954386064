/*
 *   Dialog Component
 */
const $ = require("jquery");

const DialogTemplate = [
  "<div class='dialog-overlay {{settings.dialogClass}}' data-dialog-overlay data-dialog-id='{{settings.dialogId}}' tabindex='0'>",
  "<div class='dialog' data-dialog-body>",
  "{{{dialogContent}}}",
  "</div>",
  "</div>"
].join("\n");

const Dialog = Backbone.View.extend({
  events: {
    "click [data-dialog-close]": "closeModalByKey",
    "keydown [data-dialog-close]": "closeModalByKey",
    "keyup [data-dialog-close]": "closeModalByKey"
  },
  initialize: function() {
    this.template = Handlebars.compile(DialogTemplate);

    this.$el.attr("aria-hidden", true).css({ display: "none" });

    // render is detached from init method to have it available publicly
    this.reflow();

    if (this.settings.dialogTimeout) {
      //Events that will reset inactivity timer
      var timerResetEvents = ["mousedown", "keypress", "scroll"];
      //Events listener
      timerResetEvents.forEach(
        function(name) {
          document.addEventListener(name, this.timer.bind(this));
        }.bind(this)
      );
      // Sets first timer
      this.timer();
    }

    // get dialog id
    var dialogId = this.getId();

    this.dialogTriggerHandler = '[data-dialog-trigger="' + dialogId + '"]';

    // Bind to the document namespacing with the dialog id.
    $(document).on(
      "click.dialogTrigger" + dialogId,
      this.dialogTriggerHandler,
      this.open.bind(this)
    );

    // If we want to allow an external script to open this dialog
    if (this.settings.dialogExternalTrigger) {
      this.externalDialogInit(dialogId);

      //Create the global caller function if it hasn't been created
      document.dialogTrigger =
        document.dialogTrigger || this.externalDialogTrigger;
    }

    if (this.settings.dialogOpenOnInit && this.settings.dialogShowOnce) {
      this.showDialogOnce();
    } else if (this.settings.dialogOpenOnInit) {
      this.open();
    }
  },

  timer: function() {
    //If id exists, clear timeout
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    //Sets timer
    this.timerId = setTimeout(
      this.open.bind(this),
      parseInt(this.settings.dialogTimeout) * 1000
    );
  },

  externalDialogTrigger: function(dialogId, action) {
    if (typeof dialogId != "string" || typeof action != "string") {
      console.warn(
        "You need to pass two strings: dialogId and action (open/close)"
      );
      return false;
    }
    document.pageDialogs[dialogId][action]();
    return "Component accessed";
  },

  //Saves to the document object the functions to open and close for this dialog
  externalDialogInit: function(dialogId) {
    document.pageDialogs = document.pageDialogs || {};
    document.pageDialogs[dialogId] = {};
    document.pageDialogs[dialogId].open = this.open.bind(this);
    document.pageDialogs[dialogId].close = this.close.bind(this);
  },

  getId: function() {
    return this.settings.dialogId;
  },

  // Method to close the modal by enter or spacer
  closeModalByKey: function(e) {
    if (
      e.keyCode === 32 ||
      e.keyCode === 13 ||
      e.keyCode === 27 ||
      e.type === "click"
    ) {
      this.closeModal(e);
    }
  },

  closeModal: function(e) {
    // attaching the currentModalId to a variable
    var currentModalId =
      $(e.currentTarget)
        .parents("[data-dialog-id]")
        .attr("data-dialog-id") || $(e.currentTarget).attr("data-dialog-id");

    //checks the dom if the dialogId matches with the one of the parent
    //forcing both to be strings for more accurate checkup

    if (
      currentModalId &&
      currentModalId.toString() ===
        this.currentModal.data("dialogId").toString()
    ) {
      var currentModalCheck = true;
    }

    // Check if event is coming from outside of the modal.

    if (e.target.hasAttribute("data-dialog-cancel")) {
      var dialogCancelTrigger = this.dialogTriggerHandler.closest(
        "[dialog-trigger-cancel]"
      );
    }

    if (
      (currentModalCheck &&
        (e.target.hasAttribute("data-dialog-overlay") ||
          e.target.hasAttribute("data-dialog-close") ||
          $(e.target).parents("[data-dialog-close]").length)) ||
      e.keyCode === 27
    ) {
      if (dialogCancelTrigger) {
        $(dialogCancelTrigger).removeClass("is-cancel");
      }

      // Close Modal
      this.close();
    } else if (dialogCancelTrigger) {
      $(dialogCancelTrigger).addClass("is-cancel");

      // Close Modal
      this.close();
    }
    // as this is listening to global events propagation needs to be stopped
    e.stopPropagation();
  },
  submitForm: function(e) {
    this.reflow();
    const id = $(e.currentTarget).data("dialogSubmit");
    const $form = $("[data-dialog-form=" + id + "]");

    if ($form.component().validate()) {
      //Disabling the button while interstitial is being loaded to prevent double clicking
      $("[data-dialog-submit]").attr("disabled", "disabled");
      //Submitting form

      if ($form.is("[data-dialog-form-splash]")) {
        Backbone.Events.trigger("loadingSplashOn");
      }

      $form.submit();
    } else {
      //If form did not pass validation rules, close the dialog
      this.close();
    }
  },

  close: function(callback) {
    // Remove Body State Class
    $("body").removeClass("has-active-dialog");

    // Remove state class
    this.currentModal.removeClass("is-active");
    // Standard 0.4s delay
    setTimeout(
      function() {
        // Remove children components from dialog
        if (this.currentModal) {
          this.currentModal.find("[data-component]").each(function() {
            $(this).remove();
          });

          // Remove Modal from DOM
          this.currentModal.remove();
          this.currentModal = null;
        }
      }.bind(this),
      400
    );

    // properly unbinds its events
    $(document).off("click.dialogClose." + this.getId());
    $(document).off("keydown.dialogClose." + this.getId());
    $(document).off("keyup.dialogClose." + this.getId());

    //removing the binded event that was created when dialog was opened
    $(document).off("click.dialogSubmit." + this.getId());

    if (typeof callback == "function") {
      return callback();
    }

    // Trigger a public close event.
    this.trigger("close");

    // ADA goes back to the trigger of the dialog
    $(this.dialogTriggerHandler).trigger("focus");

    //Resets timer in case that timeout setting is found
    if (this.settings.dialogTimeout) {
      this.timer();
    }

    return this;
  },
  // Public Method to open the Modal that allows:
  // {
  //  loadComponents: boolean, // Allows us to run load components on the content of the modal,
  //
  // }
  open: function(options) {
    let closeEvents = this.settings.dialogOutsideClickClose
      ? "[data-dialog-close],[data-dialog-overlay]"
      : "[data-dialog-close]";
    let dialogID = this.settings.dialogId;
    let duplicatedDialog = $("body")
      .find("[data-dialog-id=" + dialogID + "]")
      .hasClass("is-active");

    let currentTrigger = {};

    this.setModalMouseLeaveState(false);

    if (typeof options !== "undefined") {
      this.dialogTriggerHandler = options.currentTarget;
      currentTrigger = $(options.currentTarget);
    }

    this.currentModal = $(this.dialog);

    // if we want to get current Target trigger
    // check if have current Target trigger
    let currentTriggerData = {};

    if (currentTrigger.length) {
      currentTriggerData = currentTrigger.data();
    }

    // If we want to render content from an external template
    // Check if the trigger has a content id attribute
    if (
      options &&
      options.target &&
      options.target.getAttribute("data-dialog-content-id")
    ) {
      try {
        // Get the id for the content container
        const id = options.target.getAttribute("data-dialog-content-id");
        // Adding extra settings for template to recompile
        const triggerSettings =
          options.target.getAttribute("data-dialog-content-settings") || false;

        // recognizing the template and compiling it
        this.externalDialogContent = Handlebars.compile(
          $(`[data-external-content-id="${id}"]`).html()
        );

        // Update currentModal with external content
        this.currentModal = $(
          this.template({
            dialogContent: this.externalDialogContent(
              JSON.parse(triggerSettings)
            ),
            settings: this.settings
          })
        );
        this.reflow();
      } catch (e) {
        console.warn("Dialog ID not Found.");
      }
    }

    //Checking if the same dialog already opened to prevent duplicate
    // active dialogs (for example when clicking the Enter key)
    if (duplicatedDialog) {
      //Focus on current opened modal to be able close it if needed
      this.currentModal.focus();
      return false;
    }

    // add dialog to DOM and add global class

    setTimeout(
      function() {
        if (this.currentModal) {
          $("body")
            .append(this.currentModal)
            .addClass("has-active-dialog");
          this.currentModal.addClass("is-active");
        }

        // Declar object to call back options when dialog opened
        let dialogOptions = {
          modal: this.currentModal,
          triggerData: currentTriggerData
        };

        // Let the DOM know when the dialog was opened
        $(document).trigger("dialogOpen", dialogOptions);

        if (options && options.callback) {
          options.callback();
        }
      }.bind(this)
    );

    // If async, check for content
    if (this.settings.dialogAsync) {
      this.populate(options);
    }

    $(document).on("keyup.dialogClose." + this.getId(), e => {
      if (e.keyCode === 27) {
        this.closeModal.bind(this);
      }
    });

    // bind to the document to make accessible the dialog
    $(document).on(
      "click.dialogClose." + this.getId(),
      closeEvents,
      this.closeModalByKey.bind(this)
    );

    $(document).on(
      "keydown.dialogClose." + this.getId(),
      closeEvents,
      this.closeModalByKey.bind(this)
    );

    // bind to the document to make accessible the dialog
    $(document).on(
      "click.dialogSubmit." + this.getId(),
      "[data-dialog-submit]",
      this.submitForm.bind(this)
    );

    // Declar options to object modal
    $(document).on("dialogOpen." + this.getId(), (e, options) => {
      let currentModal = $(options.modal);
      currentModal.loadComponents({ options });
      currentModal.loadModules();
    });

    // ADA: focus on current content
    this.currentModal.find("[data-dialog-close], [data-dialog-action]").attr({
      role: "button",
      tabindex: 0
    });
    this.currentModal.focus();

    // Global event trigger for when the dialog opens
    Backbone.Events.trigger(`dialog-${this.settings.dialogId}-open`);

    return this;
  },
  populate: function(options) {
    // @TODO: Make optional type of request and post data
    var $dialogContent = this.currentModal.find("[data-dialog-content]");

    // Update endpoint
    this.settings.dialogAsync = this.$el.data("dialogAsync");

    if (
      options &&
      options.type &&
      _.has($(options.currentTarget).data(), "dialogTriggerUrl")
    ) {
      this.settings.dialogAsync = $(options.currentTarget).data(
        "dialogTriggerUrl"
      );

      this.loaded = false;
    }

    if (!this.loaded && !_.has(this.content, this.settings.dialogAsync)) {
      // Set loading state.
      $dialogContent.addClass("is-loading");

      $.ajax({
        type: "GET",
        dataType: "html",
        url: this.settings.dialogAsync,
        success: function(response) {
          // Store copy of the response for future renders
          this.content[this.settings.dialogAsync] = _.clone(response);

          // Populate and update loading state
          $dialogContent.html(response).removeClass("is-loading");

          // Optional Setting to AutoLoad Component can be set on options object or data-auto-load-on-open
          // attribute.
          if (
            (options && options.loadModules) ||
            this.settings.dialogAutoloadOnOpen
          ) {
            $dialogContent.loadComponents();
            $dialogContent.loadModules();
          }

          // if cache is enabled, update loaded flag
          if (this.settings.dialogCache) {
            this.loaded = true;
          }
        }.bind(this),
        error: function() {
          console.warn(arguments);
        }
      });
    } else {
      // If cache is on and cache is populated, clone the content and populate dialog
      $dialogContent.html(
        _.clone(
          this.settings.dialogAsync
            ? this.content[this.settings.dialogAsync]
            : this.content
        )
      );

      if (
        (options && options.loadModules) ||
        this.settings.dialogAutoloadOnOpen
      ) {
        $dialogContent.loadComponents();
        $dialogContent.loadModules();
      }
    }

    return this;
  },
  showDialog: function() {
    $("body").on(
      "mouseleave.showDialog." + this.cid,
      function() {
        if (sessionStorage.getItem("show-modal-on-mouse-leave") !== "false") {
          sessionStorage.setItem("show-modal-on-mouse-leave", "false");
          this.open();
          $("body").off("mouseleave.showDialog." + this.cid);
        }
      }.bind(this)
    );
  },

  showDialogOnce: function() {
    if (sessionStorage.getItem(this.settings.dialogId) !== "false") {
      sessionStorage.setItem(this.settings.dialogId, "false");
      this.open();
    }
  },

  /**
   * Sets the sessionStorage value to control the behavior of showing a modal on mouse leave.
   * This function updates the sessionStorage to indicate whether the modal should be shown on mouse leave.
   * @param {boolean} state - The state to set in sessionStorage (true or false).
   *
   */
  setModalMouseLeaveState: function(state) {
    // Set sessionStorage value to control modal behavior on mouse leave
    sessionStorage.setItem("show-modal-on-mouse-leave", state.toString());
  },

  reflow: function() {
    var defaults = {
      dialogOutsideClickClose: true,
      dialogAutoloadOnOpen: true,
      dialogId: null, // required
      dialogAsync: false,
      dialogClass: "",
      dialogCache: true,
      showDialogOnPageLeave: false,
      dialogShowOnce: false,
      dialogOpenOnInit: false
    };

    this.settings = _.extend(defaults, this.$el.data());

    this.content = this.content || {};

    if (typeof this.settings.dialogAsync == "string") {
      this.loaded = false;
    }

    this.dialog = this.template({
      dialogContent: this.$el.html(),
      settings: this.settings
    });

    if (this.settings.showDialogOnPageLeave) {
      this.showDialog();
    }

    return this;
  }
});

module.exports = Dialog;
