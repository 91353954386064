/**
 * @Version 3.1
 * @date 09/13/2018
 */

/**
 * Single Stepper default template
 */
var stepperHTML = [
  '<button type="button" class="stepper-component-control stepper-min" data-stepper-trigger="-1" tabindex="0" aria-label="Decrease value"></button>' +
    '<input class="stepper-component stepper-number" type="number" name="{{name}}" id="{{name}}" data-stepper-value autocomplete="off" min="{{min}}" max="{{max}}" aria-label="{{ariaLabel}}" {{readonly}} {{disabled}} {{{input_attributes}}}>' +
    '<button type="button" class="stepper-component-control stepper-max" data-stepper-trigger="1" tabindex="0" aria-label="Increase value"></button>'
].join("\n");

/**
 * Hidden input template for group stepper + output view
 */
var hiddenInput = `<input type="hidden" name={{name}} value={{value}}>`;

/**
 * Model that handles all data of group and children
 */
var StepperModel = function(settings) {
  var StepperModelConstructor = Backbone.Model.extend({
    defaults: {
      children: {},
      totalCount: 0
    },

    /**
     * Returns if the sum of all children steppers is between the limits of the group
     */
    checkCount: function(child, newValue, requiredFields) {
      var minValue = this.attributes.minValue,
        maxValue = this.attributes.maxValue;

      // If no boundaries where defined skip
      if (!minValue && !maxValue) {
        return true;
      }
      var children = this.toJSON().children;
      // If you have required group, get the values of the fields , and the total of those values.
      var requiredCount = requiredFields.length
        ? this.filterRequiredFields(children, child, newValue, requiredFields)
        : true;
      //get the total of the group stepper values.
      var totalCount = this.calculateTotal(children, child, newValue);

      if (totalCount >= minValue && totalCount <= maxValue && requiredCount) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Returns the total of required steppers group
     */
    filterRequiredFields: function(children, child, newValue, requiredFields) {
      var fields = requiredFields.reduce(function(allfields, cur) {
        if (cur in children) {
          allfields[cur] = children[cur];
        }
        return allfields;
      }, {});

      var requiredTotal = this.calculateTotal(fields, child, newValue);
      return requiredTotal;
    },
    /**
     * Returns the total for all the steppers in the group
     */
    calculateTotal: function(obj, child, newValue) {
      var totalCount = _.reduce(
        obj,
        function(sum, val, item) {
          return item == child ? sum + newValue : sum + val;
        },
        0
      );
      return totalCount;
    },

    /**
     * Keeps the model updated with the values of all the children
     */
    updateGroup: function(child, newValue) {
      var children = this.get("children");

      children[child] = newValue;

      // Run limit checker
      if (
        this.attributes.lowerLimitStepper ||
        this.attributes.upperLimitStepper
      ) {
        if (newValue > children[this.attributes.upperLimitStepper]) {
          children[this.attributes.upperLimitStepper] = newValue;
        } else if (newValue < children[this.attributes.lowerLimitStepper]) {
          children[this.attributes.lowerLimitStepper] = newValue;
        }
      }

      var totalCount = _.reduce(
        children,
        function(sum, val) {
          return sum + val;
        },
        0
      );

      this.set("totalCount", totalCount);
    }
  });

  return new StepperModelConstructor(settings);
};

/**
 * Stepper view single/group
 */
var StepperView = Backbone.View.extend({
  events: {
    "keydown [data-stepper-trigger]": "keyTriggerStep",
    "mouseup [data-stepper-trigger]": "clearTriggerStep",
    "mouseout [data-stepper-trigger]": "clearTriggerStep",
    "focusin [data-stepper-value]": "stopPropagation",
    "keydown [data-stepper-value]": "handlePreInput",
    "keyup [data-stepper-value]": "manualInput",
    "focus *": "inputGroupFocus",
    "blur *": "inputGroupFocus",
    "focusout [data-stepper-value]": "manualInput"
  },

  initialize: function() {
    this.settings = this.$el.data("settings") || {};
    this.settings =
      typeof this.settings == "string"
        ? JSON.parse(this.settings)
        : this.settings;

    // moving events to this method that might be
    // interfering with globalCount
    this.bindEvents();

    if (this.$el.data("stepperGroup")) {
      // If it's a group, initialize group module and don't process anything else
      this.initializeGroup();
    } else {
      // Initialize single stepper
      this.initialProcessing();
    }
  },

  /**
   * Bind events that might cause and issue with delegation
   */
  bindEvents: function() {
    this.$el.on(
      "mousedown.stepper." + this.cid,
      "[data-stepper-trigger]",
      e => {
        this.triggerStep(e);
      }
    );
  },

  /**
   * Initial processing for group steppers
   */
  initializeGroup: function() {
    //String to format output
    this.format = this.settings.format;
    //Group of required stepper (any one stepper must have a value)

    var settings = {
      minValue: this.settings.min,
      maxValue: this.settings.max,
      requiredFields: this.settings.requiredGroup || {},
      lowerLimitStepper: this.settings.lowerLimitStepper,
      upperLimitStepper: this.settings.upperLimitStepper
    };

    //If keys exist in settings, saved them to Model
    if (this.settings.defaultValues) {
      settings["defaults"] = settings["defaults"] || {};
      Object.keys(this.settings.defaultValues).forEach(key => {
        settings["defaults"][key] = this.settings.defaultValues[key];
      });
    }
    this.groupExternalOutput = this.$el.data("stepperExternalOutput") || false;

    this.groupData = StepperModel(settings);

    this.groupData.on("change:totalCount", this.renderGroupCount.bind(this));

    // Disable all events / logic of regular steppers (in case group is a wrapper of the other steppers)
    this.undelegateEvents();

    // If output-view was passed, render template with children to the utility
    if (this.settings.outputView) {
      //Template and container for hidden inputs
      this.inputTemplate = Handlebars.compile(hiddenInput);
      this.valuesContainer = this.settings.valuesContainer
        ? document.querySelector(this.settings.valuesContainer)
        : undefined;

      // Delete events object so the output-view doesn't reactivate them
      this.events = {};

      var OutputView = require("../../utilities/output-view");

      var outputView = new OutputView({
        component: this
      });

      var template = $(this.settings.templateId);
      outputView.template = template.length
        ? Handlebars.compile(template.html())
        : "";

      var outputViewTriggerWrapper = outputView.component.triggerElement.parent();

      /**
       * Overwritting Output-view functions
       * */

      // When Output-view starts serving
      // NOTE: steppers will be initialized every time the output view opens because they
      // might share the same container with other components and there is a change that content will be overwritten
      outputView.onComponentAttach = function() {
        var clickHandler = this.deviceAgent.match(/(ipod|iphone)/)
          ? "touchstart"
          : "click";

        // controls the close event for the output view

        outputView.$el.on(
          clickHandler,
          "[data-stepper-close]",
          outputView.toggleOutput.bind(this)
        );

        //Loads all components on outputView
        outputView.$el.loadComponents();

        //Setting components value to match stored in group data
        outputView.$el.find("[data-component='stepper']").each(
          function(i, item) {
            let stepperComp = $(item).component();

            //Setting value previously selected
            stepperComp.valueComponent.val(
              this.component.groupData.get("children")[stepperComp.stepperName]
            );
            // Validates input value
            stepperComp.validateInput(e);
          }.bind(outputView)
        );
        outputViewTriggerWrapper.addClass("is-active");
      };

      // When Output-view stops serving
      outputView.onComponentDetach = function() {
        //Updates hidden inputs
        outputView.component.generateHiddenInputs(outputView.component);
        outputView.disableAll();
        // Removes
        outputViewTriggerWrapper.removeClass("is-active");
      };
      /**
       * End overwritting Output-view functions
       * */
    }
  },

  inputGroupFocus: function(e) {
    var action = e.type === "focusin" ? "addClass" : "removeClass";
    $(e.currentTarget)
      .parents("[data-component='stepper']")
      [action]("is-focus");
  },

  /**
   * Generates hidden inputs when needed
   */
  generateHiddenInputs: function(context = this) {
    const children = context.groupData.get("children");
    //Container and group stepper children exist
    if (context.valuesContainer && Object.keys(children).length) {
      //Clears content stored
      let content = "";
      context.valuesContainer.innerHTML = "";

      //Adding content per children
      for (let key in children) {
        content += context.inputTemplate({
          name: key,
          value: children[key]
        });
      }
      //Adds new content
      context.valuesContainer.innerHTML = content;
    }
  },

  /**
   * Initial processing for single steppers
   */
  initialProcessing: function() {
    this.allowedCharacters = [
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      96,
      97,
      98,
      99,
      100,
      101,
      102,
      103,
      104,
      105
    ];

    // Give it a name if it doesn't have one
    this.stepperName = this.settings.name || this.cid;

    //If a custom template exists use it
    this.customTemplate = this.settings.templateId;
    this.template = this.customTemplate
      ? $("[data-template=" + this.customTemplate + "]").html()
      : stepperHTML;

    //External output
    this.externalOutput = this.$el.data("stepperExternalOutput") || false;

    //Compile template
    this.template = Handlebars.compile(this.template);
    this.$el.html(this.template(this.settings));

    // Define internal components
    this.minComponent = this.$("[data-stepper-trigger='-1']");
    this.maxComponent = this.$("[data-stepper-trigger='1']");
    this.valueComponent = this.$("[data-stepper-value]");

    //String to format output
    this.format = this.settings.format;

    // Automatically focus on input when focusing on component
    this.$el.on(
      "focus",
      function() {
        this.valueComponent.focus();
      }.bind(this)
    );

    // Initializing minimal and maximal value,
    this.minValue = this.settings.min;
    this.maxValue = this.settings.max;

    // Initializing interval for stepper, default: 1
    this.interval = this.settings.interval ? this.settings.interval : 1;

    // Initializing value if the value is passed, default: minValue
    if (
      typeof this.settings.value != "undefined" &&
      this.isBetweenBounds(this.settings.value)
    ) {
      var initialValue = this.settings.value;
    } else {
      var initialValue = this.settings.resetValue || this.minValue;
    }

    this.value = initialValue || 0;
    this.defaultValue = initialValue || 0;

    //If stepper belongs to a group connect to group's model | render on model change
    var group = $('[data-stepper-group="' + this.settings.groupId + '"]');

    if (group.length) {
      this.groupData = group.component().groupData;
      this.groupData.on(
        "change:totalCount",
        function() {
          this.value = this.groupData.get("children")[this.stepperName];
          this.render();
          this.updateButtonStatus();

          // if the combinedTotal setting is present calculate between stepper types
          if (this.settings.combinedTotal) {
            this.renderGlobalCount();
          }
        }.bind(this)
      );

      // If group value does not exist, update group with new value
      if (this.groupData.get("children")[this.stepperName] === undefined) {
        this.groupData.updateGroup(this.stepperName, this.value);
      }
    }

    this.render();
  },

  /**
   * By default we focus on the input field when we focus the component,
   * so we need to stop the event bubbling to avoid a focus loop
   */
  stopPropagation: function(e) {
    e.stopPropagation();
  },

  reset: function(e) {
    // We only reset single steppers
    if (!this.$el.data("stepperGroup")) {
      var resetValue = this.settings.resetValue || this.settings.min;
      this.value = resetValue;
      this.render(e);
      var passengerCount = $("[data-passenger-count]");

      if (this.groupData) {
        this.groupData.updateGroup(this.stepperName, this.value);

        if (passengerCount) {
          this.renderGroupCount();
        }
      }
    } else if (
      // Special case when we have steppers contained in an output view
      this.$el.data("stepperGroup") &&
      this.settings.outputView &&
      this.groupData.get("defaults") &&
      this.groupData.get("children")
    ) {
      // Default single steppers value (values saved using stepper name)
      const defaults = this.groupData.get("defaults");
      //Resetting group data for children to default values (default keys match stepper names)
      Object.keys(defaults).forEach(key => {
        this.groupData.updateGroup(key, defaults[key]);
      });
      this.generateHiddenInputs();
    }
  },

  render: function(e) {
    // If we have format use it, else just pass the number
    if (this.format) {
      this.$el.attr(
        "data-label-formatted-value",
        this.formatOutput(this.value, this.format)
      );
    }

    // Render input
    this.valueComponent.val(this.value);

    // if there's an external output use it
    if (this.externalOutput) {
      this.renderExternalOutput(e, this.externalOutput, this.value);
    }
  },

  renderExternalOutput: function(e, field, value) {
    const currentExternalField = $(
      "[data-stepper-external-value=" + field + "]"
    );
    currentExternalField.html(value);
  },

  renderGroupCount: function(e) {
    var totalCount = this.format
      ? this.formatOutput(this.groupData.get("totalCount"), this.format)
      : this.groupData.get("totalCount");

    $("[data-passenger-count]").val(totalCount);

    if (this.el.tagName == "INPUT") {
      this.$el.val(totalCount);
    } else {
      this.$el.attr("data-text", totalCount);
    }

    // if there's an external output use it
    if (this.groupExternalOutput) {
      this.renderExternalOutput(e, this.groupExternalOutput, totalCount);
    }
  },

  renderGlobalCount: function(e) {
    const parent = this.$el.parents("[data-stepper-total-wrapper]");
    const children = parent.find("[data-stepper-type-count]");

    const uniqueTypesSet = new Set();

    // getting available types in view
    children.each(function() {
      const value = $(this).data("stepperTypeCount");
      uniqueTypesSet.add(value);
    });

    const uniqueTypes = Array.from(uniqueTypesSet);

    const totals = _.map(children, child => {
      const childComponent = $(child).component();
      const childComponentData = childComponent.groupData;

      $(child)
        .off("mousedown")
        .undelegate("mousedown");

      childComponent.bindEvents();

      return childComponentData.get("children");
    });

    // Get unique totals across all child components
    const uniqueTotals = _.uniq(totals);

    // Calculate summed data for unique types
    const summedData = {};
    _.each(uniqueTotals, function(obj) {
      _.each(obj, function(value, key) {
        _.each(uniqueTypes, function(type) {
          if (key.includes(type)) {
            summedData[type] = (summedData[type] || 0) + value;
          }
        });
      });
    });

    _.each(summedData, (value, key) => {
      this.renderExternalOutput(e, key, value);
    });
  },

  updateButtonStatus: function() {
    //Add inactive class to '-' or '+' button when the input value == to minValue or maxValue
    //and when can be added or subtracted by the interval value.
    if (
      this.minValue == this.value ||
      this.value - this.interval < this.minValue
    ) {
      this.minComponent.attr("disabled", "disabled");
    } else {
      this.minComponent.removeAttr("disabled");
    }

    if (
      this.maxValue == this.value ||
      this.value + this.interval > this.maxValue
    ) {
      this.maxComponent.attr("disabled", "disabled");
    } else {
      this.maxComponent.removeAttr("disabled");
    }
  },

  /**
   * Format output value if format was defined
   */
  formatOutput: function(value, format) {
    var regex = /\$./g;
    //If we passed formats for single and many values use appropriate format
    if (typeof format == "object") {
      //Check that object was defined properly
      if (format.single && format.many) {
        //If value is 1 use format for single selection else use the format for many
        format = value == 1 ? format.single : format.many;
      } else if (format.summary) {
        format = format.summary;
        regex = /\$(\S*)\$/g;
        var functionValue = function(item) {
          var item = item.slice(1, -1);
          return this.groupData.get("children")[item];
        }.bind(this);
      } else {
        var error =
          "Please define format object as described on the documentation";
      }

      // If a number format was passed
    } else if (/^x+$/g.test(format)) {
      while (String(value).length < (format.length || 2)) {
        value = "0" + value;
      }
      return value;

      // If the format passed is an incorrect type
    } else if (typeof format != "object" && typeof format != "string") {
      //If anything other than a string or an object was passed abort
      var error = "Format object must be a string or object";
    }

    if (error) {
      console.warn(error);
      return value;
    }

    return format.replace(regex, functionValue || value);
  },

  /**
   * Called when clicking on triggers or when using arrows
   */
  triggerStep: function(e) {
    var multiplier =
      typeof e == "number" ? e : $(e.currentTarget).data("stepperTrigger");
    this.valueComponent.val(this.value + Number(multiplier) * this.interval);
    this.validateInput(e);

    // Create a 500ms delay before creating the interval
    this.triggerStepTimeout = setTimeout(
      function() {
        if (!this.triggerStepInterval && typeof e == "object") {
          // We run the setInterval only if the action comes from a mouse click and it hasn't been declared
          this.triggerStepInterval = setInterval(
            function() {
              this.valueComponent.val(
                this.value + Number(multiplier) * this.interval
              );
              let isValid = this.validateInput();

              if (!isValid) {
                this.clearTriggerStep();
              }
              return false;
            }.bind(this),
            100
          );
        }
      }.bind(this),
      500
    );
  },

  /**
   * Called when reaching the controls via keyboard
   */
  keyTriggerStep: function(e) {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.triggerStep(parseInt(e.currentTarget.dataset.stepperTrigger));
    }
  },

  /**
   * Stops the stepper on mouseout/mouseup
   */
  clearTriggerStep: function() {
    clearInterval(this.triggerStepInterval);
    clearTimeout(this.triggerStepTimeout);
    this.triggerStepInterval = false;
    this.triggerStepTimeout = false;
  },

  /**
   * Handles key pressed for different actions
   */
  handlePreInput: function(e) {
    let pressedKey = typeof e.which == "number" ? e.which : e.keyCode;

    // If anything other than number, tab, del or backspace was pressed cancel input
    if (
      this.allowedCharacters.indexOf(pressedKey) === -1 &&
      pressedKey === 13
    ) {
      this.validateInput(e);
    }
  },

  /**
   * Handles interactions with keyboard
   */
  manualInput: function(e) {
    // Keep window from scrolling when pressing arrows
    e.preventDefault();
    if (e.keyCode !== 32 || e.keyCode !== 13) {
      // If current value is 0, clear it
      if (this.valueComponent.val() === 0) {
        this.valueComponent.val("");
      }
      // If we have a format and the input complies with it validate
      if (this.format && !/^x+$/g.test(this.format)) {
        // If there's a format but manual input doesn't comply with it, undo last entry
        this.valueComponent.val(this.formatOutput(this.value, this.format));
      }

      //Clear timeout first
      if (this.manualInputTimeout) {
        clearTimeout(this.manualInputTimeout);
      }

      this.manualInputTimeout = setTimeout(
        function() {
          this.validateInput(e);
        }.bind(this),
        300
      );
    }
  },

  /**
   *  Calculates if new value is between the min and maximum values of stepper and/or group stepper
   */
  isBetweenBounds: function(newValue) {
    var minValue =
        typeof this.minValue != "undefined" ? this.minValue : newValue,
      maxValue = typeof this.maxValue != "undefined" ? this.maxValue : newValue;

    if (newValue >= minValue && newValue <= maxValue) {
      return true;
    } else {
      return false;
    }
  },

  /**
   * Validate new value
   */
  validateInput: function(e) {
    // Take the value from the input
    var newValue = Number(this.valueComponent.val());

    // Calculate if the new value is multiple of interval
    var multiple = (newValue - this.defaultValue) % this.interval;

    var newValueisValid = this.isBetweenBounds(newValue);

    // If stepper belongs to a group check group limits and the required group limits
    var reachedGroupLimit = this.groupData
      ? this.groupData.checkCount(
          this.stepperName,
          newValue,
          this.groupData.get("requiredFields")
        )
      : true;

    // If new value is valid replace this.value
    if (!multiple && newValueisValid && reachedGroupLimit) {
      this.value = newValue;

      // Update group with new value
      if (this.groupData) {
        this.groupData.updateGroup(this.stepperName, newValue);
      }

      // Activate/Deactivate buttons
      this.updateButtonStatus();
    }

    // Format new value or renders again old value if current one was invalid
    this.render(e);

    // Trigger a change event
    if (newValueisValid) {
      this.valueComponent.trigger("change");

      this.renderGlobalCount();

      return true;
    }

    // If value is not valid
    return false;
  }
});

module.exports = StepperView;
