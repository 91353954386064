// When an agency doesn't have a gmapKey assigned
// this alternative map will be used
// For documentation on the leaflet map, please go to
// https://leafletjs.com/reference-1.3.4.html

const MapsLoader = require("leaflet");
require("leaflet.fullscreen");

var LeafletMaps = Backbone.View.extend({
  // Assigning custom icons to markers to each case

  createMarkers: function() {
    _.each(
      this.settings.markers,
      function(setMarker, index) {
        if (
          this.startEndMarkerIcon &&
          setMarker.id == this.settings.map.firstMarkerId &&
          setMarker.id == this.settings.map.lastMarkerId
        ) {
          this.currentMarker = this.startEndMarkerIcon;
        } else if (
          this.startMarkerIcon &&
          setMarker.id == this.settings.map.firstMarkerId
        ) {
          this.currentMarker = this.startMarkerIcon;
        } else if (
          this.endMarkerIcon &&
          setMarker.id == this.settings.map.lastMarkerId
        ) {
          this.currentMarker = this.endMarkerIcon;
        } else if (this.baseMarkerIcon) {
          this.currentMarker = this.baseMarkerIcon;
        }

        var options = {};

        if (this.currentMarker) {
          options.icon = this.currentMarker;
        }

        setMarker.latitude = parseFloat(setMarker.latitude);
        setMarker.longitude = parseFloat(setMarker.longitude);

        // Creative solution to support world cruises
        if (setMarker.longitude < 0 && this.settings.map.destinationId === 40) {
          setMarker.longitude += 360;
        }

        // assigning the custom icon to the marker and attaching it to the map
        var marker = L.marker(
          [setMarker.latitude, setMarker.longitude],
          options
        ).addTo(this.mapWrapper);

        // creating the tooltip for each marker
        marker.bindPopup("<b>" + setMarker.title + "</b>");

        // building array with all the markers
        var currentMarker = L.latLng(setMarker.latitude, setMarker.longitude);

        this.markers = this.markers.concat(currentMarker);
      }.bind(this)
    );
  },

  // defining all different versions of icons that we have in the system
  defineCustomMarkers: function() {
    // setting up the default values of all rvlx icon types
    var rvlxIcon = L.Icon.extend({
      options: {
        iconSize: [this.settings.icons.width, this.settings.icons.height],
        iconAnchor: [this.settings.icons.width, this.settings.icons.height],
        popupAnchor: [
          parseFloat(this.settings.popup.locationX),
          parseFloat(this.settings.popup.locationY)
        ]
      }
    });

    if (this.settings.icons.base) {
      this.baseMarkerIcon = new rvlxIcon({
        iconUrl: this.settings.icons.base
      });
    }

    if (this.settings.icons.start) {
      this.startMarkerIcon = new rvlxIcon({
        iconUrl: this.settings.icons.start
      });
    }

    if (this.settings.icons.end) {
      this.endMarkerIcon = new rvlxIcon({
        iconUrl: this.settings.icons.end
      });
    }

    if (this.settings.icons.startEnd) {
      this.startEndMarkerIcon = new rvlxIcon({
        iconUrl: this.settings.icons.startEnd
      });
    }
  },

  // centering the map to the locations of markers
  defineMapBounds: function() {
    this.mapWrapper.fitBounds(this.markers, {
      padding: [this.settings.map.padding, this.settings.map.padding]
    });
  },

  initMap: function() {
    // Leaflet deals with the container id,
    // which is composed from mapContainer + sailingID
    const mapID = this.mapContainer.attr("id");

    // due to the issue of thread not being available on details,
    // using cid for uniqueness
    const currentMapId = mapID + this.cid;

    // replace container with unique ID
    this.mapContainer.attr("id", currentMapId);

    // Creating instance of the map and passing initial latLng and zoom
    this.mapWrapper = L.map(currentMapId).setView(
      [this.settings.map.latitude, this.settings.map.longitude],
      this.settings.map.zoom
    );

    // Leaflet is an overlay and always need a tile system, leaflet recommends mapBox
    // but is a paid tile system, we are using openStream free one
    // to get examples of free providers check https://leaflet-extras.github.io/leaflet-providers/preview/
    L.tileLayer(
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
      {
        attribution:
          'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012 &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright" target="_blank" class="leaflet-copyright">OpenStreetMap Copyright</a>',
        minZoom: this.settings.map.minZoom,
        maxZoom: this.settings.map.maxZoom
      }
    ).addTo(this.mapWrapper);

    L.control
      .fullscreen({
        position: "topleft", // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
        title: "Show me the fullscreen !", // change the title of the button, default Full Screen
        titleCancel: "Exit fullscreen mode", // change the title of the button when fullscreen is on, default Exit Full Screen
        content: null, // change the content of the button, can be HTML, default null
        forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
        forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
        fullscreenElement: false // Dom element to render in full screen, false by default, fallback to map._container
      })
      .addTo(this.mapWrapper);

    this.defineCustomMarkers();
    this.createMarkers();
    this.defineMapBounds();
  },
  initialize: function() {
    // Used to import the css from node_modules
    require("leaflet/dist/leaflet.css");
    require("leaflet.fullscreen/Control.FullScreen.css");

    // set default settings
    this.settings = {
      icons: {
        base: "",
        start: "",
        end: "",
        startEnd: "",
        width: 27,
        height: 34
      },
      map: {
        latitude: 0,
        longitude: 0,
        zoom: 3,
        minZoom: 1,
        maxZoom: 8,
        mapType: "roadmap",
        mapTypeControl: true,
        streetViewControl: true,
        firstMarkerId: null,
        lastMarkerId: null,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: "topleft"
        },
        padding: 0,
        destinationId: false
      },
      popup: {
        locationX: "-14",
        locationY: "-39"
      },
      markers: []
    };

    this.markers = [];

    if (this.$el.data("map-settings")) {
      this.settings = $.extend(
        true,
        this.settings,
        this.$el.data("map-settings")
      );
    }

    this.mapContainer = this.$("[data-map-container]");
    this.initMap();
  }
});

module.exports = LeafletMaps;
